<template>
  <div>
    <vs-button
      :disabled="disabled"
      @click="openMessagesPopup"
    >
      <i class="mdi mdi-email-outline mr-1" />

      Gerenciar Mensagens
    </vs-button>

    <!-- Messages Popup -->
    <vs-popup
      title="Gerenciar Mensagens"
      :active.sync="messagesPopup"
    >
      <form class="d-flex flex-column flex-nowrap">
        <!-- Stepper -->
        <vs-collapse
          accordion
          class="prize-stepper"
          type="margin"
        >
          <!-- Invite Template Step -->
          <vs-collapse-item
            id="first-step"
            :disabled="stepper !== 0"
            icon-arrow="x"
          >
            <div
              slot="header"
              class="d-flex align-items-center"
            >
              <vs-avatar
                :color="stepper > 0 ? 'secondary' : 'primary'"
                class="mr-3"
                size="small"
                text="1"
              />

              <h6>Convite para o Sorteio</h6>
            </div>

            <section class="px-2">
              <p>Poderá ser ativado manualmente para convidar os participantes para o sorteio.</p>

              <div class="d-flex justify-content-between">
                <SelectTemplate
                  :templates="emailTemplates"
                  template-type="email"
                  :value="inviteTemplate"
                  @set-value="setInviteTemplate"
                />

                <vs-button
                  class="align-self-end"
                  :disabled="inviteTemplate === 0"
                  icon="arrow_forward"
                  icon-after
                  @click="nextStep"
                >
                  Continuar
                </vs-button>
              </div>
            </section>
          </vs-collapse-item>

          <!-- Confirmation Template Step -->
          <vs-collapse-item
            id="step-two"
            :disabled="stepper !== 1"
            icon-arrow="x"
          >
            <div
              slot="header"
              class="d-flex align-items-center"
            >
              <vs-avatar
                :color="
                  stepper === 1 ? 'primary' :
                  stepper > 1 ? 'secondary' :
                  'darkgray'
                "
                class="mr-3"
                size="small"
                text="2"
              />

              <h6>
                Confimação de Participação
                <small>(Opcional)</small>
              </h6>
            </div>

            <section class="px-2">
              <p>Será enviado automaticamente para o participante após a finalização da inscrição.</p>

              <div class="d-flex justify-content-between">
                <SelectTemplate
                  :templates="emailTemplates"
                  template-type="email"
                  :value="confirmationTemplate"
                  @set-value="setConfirmationTemplate"
                />

                <div class="d-flex align-self-end">
                  <vs-button
                    class="mr-2"
                    color="dark"
                    @click="previousStep"
                  >
                    Voltar
                  </vs-button>

                  <vs-button
                    icon="arrow_forward"
                    icon-after
                    @click="nextStep"
                  >
                    Continuar
                  </vs-button>
                </div>
              </div>
            </section>
          </vs-collapse-item>

          <!-- Announcement Template Step -->
          <vs-collapse-item
            id="step-three"
            :disabled="stepper !== 2"
            icon-arrow="x"
          >
            <div
              slot="header"
              class="d-flex align-items-center"
            >
              <vs-avatar
                :color="
                  stepper === 2 ? 'primary' :
                  stepper > 2 ? 'secondary' :
                  'darkgray'
                "
                class="mr-3"
                size="small"
                text="3"
              />

              <h6>Anúncio do Sorteio</h6>
            </div>

            <section class="px-2">
              <p>Poderá ser ativado manualmente para anunciar a data e horário do sorteio.</p>

              <div class="d-flex justify-content-between">
                <SelectTemplate
                  :templates="emailTemplates"
                  template-type="email"
                  :value="announcementTemplate"
                  @set-value="setAnnouncementTemplate"
                />

                <div class="d-flex align-self-end">
                  <vs-button
                    class="mr-2"
                    color="dark"
                    @click="previousStep"
                  >
                    Voltar
                  </vs-button>

                  <vs-button
                    :disabled="announcementTemplate === 0"
                    icon="arrow_forward"
                    icon-after
                    @click="nextStep"
                  >
                    Continuar
                  </vs-button>
                </div>
              </div>
            </section>
          </vs-collapse-item>

          <!-- Results Template Step -->
          <vs-collapse-item
            id="last-step"
            :disabled="stepper !== 3"
            icon-arrow="x"
          >
            <div
              slot="header"
              class="d-flex align-items-center"
            >
              <vs-avatar
                :color="stepper === 3 ? 'primary' : 'darkgray'"
                class="mr-3"
                size="small"
                text="4"
              />

              <h6>Resultados</h6>
            </div>

            <section class="px-2">
              <p>Poderá ser ativado manualmente para notificar os participantes.</p>

              <div class="d-flex justify-content-between">
                <SelectTemplate
                  :templates="emailTemplates"
                  template-type="email"
                  :value="resultsEmailTemplate"
                  @set-value="setResultsEmailTemplate"
                />

                <SelectTemplate
                  :templates="smsTemplates"
                  template-type="sms"
                  :value="resultsMessageTemplate"
                  @set-value="setResultsMessageTemplate"
                />

                <div class="d-flex align-self-end">
                  <vs-button
                    class="mr-2"
                    color="dark"
                    @click="previousStep"
                  >
                    Voltar
                  </vs-button>

                  <vs-button
                    :disabled="resultsEmailTemplate === 0"
                    @click="closeMessagesPopup"
                  >
                    Concluir
                  </vs-button>
                </div>
              </div>
            </section>
          </vs-collapse-item>
        </vs-collapse>
      </form>
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import SelectTemplate from './SelectTemplate.vue';

/* Services */
import Campaign from '@/services/campaign';
import Template from '@/services/template';

const campaignService = new Campaign();
const templateService = new Template();

/* Style */
import '../assets/index.scss';

export default {
  name: 'PrizeTemplatesForm',
  components: { SelectTemplate },
  props: {
    announcementTemplate: {
      type: Number,
      required: true,
    },
    confirmationTemplate: {
      type: Number,
      required: true,
    },
    inviteTemplate: {
      type: Number,
      required: true,
    },
    resultsEmailTemplate: {
      type: Number,
      required: true,
    },
    resultsMessageTemplate: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    emailTemplates: [],
    messageTemplates: [],
    /* Stepper */
    stepper: 0,
    /* Popups */
    messagesPopup: false,
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Data */
    smsTemplates() {
      if (this.messageTemplates.length === 0) {
        return [];
      } else {
        let filtered = [...this.messageTemplates];

        filtered = filtered.filter((template) => {
          return template.contents.some((c) => {
            return c.type.toLowerCase() === 'sms';
          });
        });

        return filtered;
      }
    },
    // whatsappTemplates() {
    //   if (this.messageTemplates.length === 0) {
    //     return [];
    //   } else {
    //     let filtered = [...this.messageTemplates];

    //     filtered = filtered.filter((template) => {
    //       return template.contents.some((c) => {
    //         return c.type.toLowerCase() === 'whatsapp';
    //       });
    //     });

    //     return filtered;
    //   }
    // },
  },
  watch: {
    messagesPopup(value) {
      if (value) {
        this.stepper = 0;
        this.openCollapseItem('first-step');
      }
    },
  },
  created() {
    this.getEmailTemplates();
    this.getMessageTemplates();
  },
  methods: {
    /* API */
    getEmailTemplates() {
      this.$store.dispatch('set_isLoadingActive', true);

      templateService.getTemplates(this.ownerId)
        .then((res) => this.emailTemplates = res)
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getMessageTemplates() {
      this.$store.dispatch('set_isLoadingActive', true);

      campaignService.getMessageTemplates(this.ownerId)
        .then((res) => this.messageTemplates = res)
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    previousStep() {
      this.stepper -= 1;

      if (this.stepper === 0) {
        this.openCollapseItem('first-step');
      } else if (this.stepper === 1) {
        this.openCollapseItem('step-two');
      } else if (this.stepper === 2) {
        this.openCollapseItem('step-three');
      }
    },
    nextStep() {
      this.stepper += 1;

      if (this.stepper === 1) {
        this.openCollapseItem('step-two');
      } else if (this.stepper === 2) {
        this.openCollapseItem('step-three');
      } else if (this.stepper === 3) {
        this.openCollapseItem('last-step');
      }
    },
    openCollapseItem(elementId) {
      // Wait for vs-collapse-item arrow button to render properly
      const ms = 100;

      setTimeout(() => {
        const target = document.getElementById(elementId);

        if (!target.classList.contains('open-item')) {
          const button = target.querySelector('.vs-collapse-item--icon-header');
          button.click();
        }
      }, ms);
    },
    /* Emits */
    setAnnouncementTemplate(value) {
      this.$emit('set-announcement-template', value);
    },
    setConfirmationTemplate(value) {
      this.$emit('set-confirmation-template', value);
    },
    setInviteTemplate(value) {
      this.$emit('set-invite-template', value);
    },
    setResultsEmailTemplate(value) {
      this.$emit('set-results-email-template', value);
    },
    setResultsMessageTemplate(value) {
      this.$emit('set-results-message-template', value);
    },
    /* Popups */
    closeMessagesPopup() {
      this.messagesPopup = false;
    },
    openMessagesPopup() {
      this.messagesPopup = true;
    },
  },
};
</script>

<style lang="scss">
.prize-stepper {
  .vs-collapse-item.disabledx {
    opacity: 0.9 !important;
  }
}
</style>
