const validatePrizeProject = (draw) => {
  const {
    prizeName,
    filterId,
    urlSurveyId,
    signToParticipate,
  } = draw;

  const validString = (t) => {
    return (typeof t === 'string' && t.length > 1);
  };

  const validMethod = signToParticipate
    ? urlSurveyId > 0
    : filterId > 0;

  return validString(prizeName) && validMethod;
};

export default validatePrizeProject;
