<template>
  <vs-col
    vs-lg="6"
    vs-sm="6"
    :style="{ paddingLeft: '2rem' }"
  >
    <h6 class="mb-3">
      URL da Pesquisa
    </h6>

    <!-- Main Form -->
    <vs-row>
      <!-- Choose URL -->
      <vs-col
        vs-lg="12"
        vs-sm="12"
        class="mb-4 c-select-prize-survey"
      >
        <vs-select
          v-model="urlSurveyIdModel"
          :disabled="!signToParticipate"
          placeholder="Selecionar opção..."
        >
          <vs-select-item
            v-for="survey in filteredSurveys"
            :key="survey.surveyId"
            :value="survey.surveyId"
            :text="survey.url"
          />
        </vs-select>
      </vs-col>
    </vs-row>
  </vs-col>
</template>

<script>
/* Services */
import Prize from '@/services/prize';

const prizeService = new Prize();

export default {
  name: 'PrizeSurveyForm',
  props: {
    urlSurveyId: {
      type: Number,
      required: true,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    signToParticipate: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    drawSurveys: [],
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    filteredSurveys() {
      return this.drawSurveys.filter((survey) => survey.url.length > 0);
    },
    /* Models */
    urlSurveyIdModel: {
      get() {
        if (this.surveyId) {
          const found = this.filteredSurveys.find((sv) => {
            return sv.surveyId === this.surveyId;
          });

          if (!found) return 0;

          return this.surveyId;
        } else {
          const found = this.filteredSurveys.find((sv) => {
            return sv.surveyId === this.urlSurveyId;
          });

          if (!found) return 0;

          return this.urlSurveyId;
        }
      },
      set(value) { this.$emit('set-url-survey-id', value) },
    },
  },
  watch: {
    urlSurveyId(newValue, oldValue) {
      this.openConfirmUrlDialog(newValue, oldValue);
    },
  },
  created() {
    this.getDrawSurveys();
  },
  methods: {
    /* API */
    getDrawSurveys() {
      prizeService
        .getDrawSurveys(this.ownerId, this.token)
        .then((resp) => (this.drawSurveys = resp));
    },
    /* Popups */
    openConfirmUrlDialog(newSurveyId, oldSurveyId) {
      const oldUrl = this.filteredSurveys.find((sv) => {
        return sv.surveyId === oldSurveyId;
      });

      const newUrl = this.filteredSurveys.find((sv) => {
        return sv.surveyId === newSurveyId;
      });

      if (oldUrl && newUrl) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'URL da Pesquisa',
          acceptText: 'Confirmar',
          cancelText: 'Cancelar',
          text: `
            Ao confirmar a alteração de URL, a página situada em  "${newUrl.url}"
            (atualmente com o projeto "${oldUrl.prizeName}")
            direcionará para "${newUrl.prizeName}".
            A alteração poderá demorar até 10 minutos para refletir na página.
          `,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.c-select-prize-survey .con-select {
  width: 300px !important;
}
</style>
